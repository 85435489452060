import { APIHostURL, UIPublicBaseURL } from "@/nebulaAPI/consts";

export function getLoginUrl({
  redirectTo,
  cliSessionId,
  orgInviteCode,
}: {
  redirectTo?: string;
  cliSessionId?: string;
  orgInviteCode?: string;
}) {
  return `${APIHostURL}/app/auth/web/github?return_to=${encodeURIComponent(
    `${UIPublicBaseURL}/auth/login/done${
      cliSessionId
        ? `?sessionId=${cliSessionId}`
        : orgInviteCode
          ? `?org_invite_code=${orgInviteCode}`
          : redirectTo
            ? `?redirect=${redirectTo}`
            : ""
    }`
  )}`;
}

export async function sleep(timeout: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, timeout));
}

export async function backoffRetry<T>(
  action: () => Promise<T>,
  attempts: number = 4
): Promise<T> {
  let i = 0;
  while (true) {
    try {
      return await action();
    } catch (err) {
      if (i > attempts) {
        throw err;
      }
      await sleep(2 ** i * 100 + Math.random() * 100);
      i++;
    }
  }
}
