import { createAPIGetter, nebulaClient, z } from ".";

import { compareVersions } from "@/components/utils";

const ServerVersionType = z.object({
  name: z.string(),
  version: z.string(),
  preview: z.boolean().optional().default(false),
});

export type ServerVersion = z.infer<typeof ServerVersionType>;

const ServerVersionTypeArray = z.array(ServerVersionType);

export const getServerVersions = createAPIGetter(
  async () => {
    return ServerVersionTypeArray.parse(
      await nebulaClient.get(`versions`)
    ).sort((a, b) => compareVersions(b.version, a.version));
  },
  () => "versions"
);

const RegionType = z.object({
  id: z.string(),
  name: z.string(),
  platform: z.string(),
  platform_region: z.string(),
});

export type Region = z.infer<typeof RegionType>;

const RegionTypeArray = z.array(RegionType);

export const getRegions = createAPIGetter(
  async () => {
    return RegionTypeArray.parse(await nebulaClient.get(`region`));
  },
  () => "region"
);

export const getClosestRegion = createAPIGetter(
  async () => {
    return RegionType.parse(await nebulaClient.get("region/self"));
  },
  () => "region/self"
);
