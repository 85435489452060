import { createAPIGetter, z, zDate, zLooseEnum } from ".";

const BaseIncidentType = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
});

const IncidentsType = z.object({
  ongoing_incidents: z.array(
    BaseIncidentType.extend({
      last_update_at: zDate,
      status: zLooseEnum(["identified", "investigating", "monitoring"]),
      current_worst_impact: zLooseEnum([
        "partial_outage",
        "degraded_performance",
        "full_outage",
      ]),
    })
  ),
  in_progress_maintenances: z.array(
    BaseIncidentType.extend({
      started_at: zDate,
      scheduled_end_at: zDate,
    }).transform((inc) => ({
      ...inc,
      starts_at: inc.started_at,
      ends_at: inc.scheduled_end_at,
    }))
  ),
  scheduled_maintenances: z
    .array(
      BaseIncidentType.extend({
        starts_at: zDate,
        ends_at: zDate,
      })
    )
    .transform((maintenances) =>
      maintenances.sort((a, b) => a.starts_at.getTime() - b.starts_at.getTime())
    ),
});

export const getIncidents = createAPIGetter(
  async () => {
    let data: unknown = null;
    if (
      import.meta.env["VITE_VERCEL_ENV"] !== "production" &&
      localStorage.getItem("_incidents")
    ) {
      data = JSON.parse(localStorage.getItem("_incidents")!);
    } else {
      const res = await fetch("https://www.gelstatus.com/api/v1/summary");
      if (res.ok) {
        data = await res.json();
      }
    }
    return data ? IncidentsType.parse(data) : null;
  },
  () => "_incidents"
);
