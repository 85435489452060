import Fraction from "fraction.js";

import { createAPIGetter, nebulaClient, z, zLooseEnum } from ".";

const PricingTierType = z.enum(["Free", "Pro"]);

export type PricingTier = z.infer<typeof PricingTierType>;

const PricingType = z.object({
  billables: z.array(
    z.object({
      id: z.string(),
      name: zLooseEnum(["compute", "storage", "transfer", "backup"]),
      display_name: z.string(),
      kind: zLooseEnum(["Allocated", "Metered"]),
      display_unit: z.string(),
      billing_increment_seconds: z.number().nullable(),
      billing_increment_display: z.string().nullable(),
      selectable_factors_display: z.array(z.string()).nullable(),
      resources: z.array(
        z.object({
          name: z.string(),
          display_unit: z.string(),
          display_increment: z.string(),
        })
      ),
      default_org_limits: z
        .array(
          z.object({
            tier: PricingTierType,
            limit: z.string(),
          })
        )
        .nullable(),
      default_instance_limits: z
        .array(
          z.object({
            tier: PricingTierType,
            limit: z.string(),
          })
        )
        .nullable(),
    })
  ),
  prices: z.record(
    PricingTierType,
    z.record(
      z.array(
        z.object({
          billable: z.string(),
          unit_price_cents: z.string(),
          units_bundled: z.string(),
        })
      )
    )
  ),
});

type RawPricingData = z.infer<typeof PricingType>;

function getProPricesForBillableId(
  prices: RawPricingData["prices"],
  id: string
) {
  return Object.entries(prices["Pro"] ?? {}).reduce(
    (tierPricing, [region, prices]) => {
      const price = prices.find((p) => p.billable === id);
      if (price != null) {
        tierPricing[region] = {
          unitPriceCents: parseFloat(price.unit_price_cents),
          bundledUnits: parseFloat(price.units_bundled),
        };
      }
      return tierPricing;
    },
    {} as {
      [region: string]:
        | { unitPriceCents: number; bundledUnits: number }
        | undefined;
    }
  );
}

const computeResourceOrder = ["memory", "cpu"]; // reverse order

export const getPricing = createAPIGetter(
  async () => {
    const data = PricingType.parse(
      await nebulaClient.get("pricing", undefined, false)
    );
    const billables = data.billables.reduce(
      (billables, billable) => {
        billables[billable.name] = billable;
        return billables;
      },
      {} as {
        [key in RawPricingData["billables"][number]["name"]]: RawPricingData["billables"][number];
      }
    );

    for (const key of ["compute", "storage", "transfer"] as const) {
      if (!billables[key]) {
        throw new Error(`Pricing data for '${key}' not found`);
      }
      if (
        billables[key].default_org_limits == null ||
        billables[key].default_instance_limits == null
      ) {
        throw new Error(
          `'default_org_limits' or 'default_instance_limits' for '${key}' ` +
            `billable is null, expected array`
        );
      }
    }

    const { compute, storage, transfer } = billables;

    return {
      compute: {
        billingIncrementSeconds: compute.billing_increment_seconds!,
        billingIncrementDisplay: compute.billing_increment_display!,
        selectableValues: compute.selectable_factors_display!.map(
          (factor) => new Fraction(factor)
        ),
        resources: compute.resources
          .sort(
            (a, b) =>
              computeResourceOrder.indexOf(b.name) -
              computeResourceOrder.indexOf(a.name)
          )
          .map((resource) => ({
            unit: resource.display_unit,
            increment: new Fraction(resource.display_increment),
          })),
        pricing: getProPricesForBillableId(data.prices, compute.id),
        freeLimit: new Fraction(
          compute.default_instance_limits!.find((l) => l.tier === "Free")!.limit
        ),
      },
      storage: {
        billingIncrementSeconds: storage.billing_increment_seconds!,
        billingIncrementDisplay: storage.billing_increment_display!,
        selectableMaxLimit: parseInt(
          storage.default_instance_limits!.find((l) => l.tier === "Pro")!.limit,
          10
        ),
        resourceUnit: storage.resources[0].display_unit,
        pricing: getProPricesForBillableId(data.prices, storage.id),
        freeLimit: storage.default_instance_limits!.find(
          (l) => l.tier === "Free"
        )!.limit,
      },
      transfer: {
        resourceUnit: transfer.resources[0].display_unit,
        pricing: getProPricesForBillableId(data.prices, transfer.id),
        freeLimit: transfer.default_instance_limits!.find(
          (l) => l.tier === "Free"
        )!.limit,
      },
      disabledTiers: new Set(
        compute
          .default_org_limits!.filter(({ limit }) => limit === "0")
          .map(({ tier }) => tier)
      ),
    };
  },
  () => "pricing"
);

export type PricingData = Awaited<ReturnType<typeof getPricing>>;
