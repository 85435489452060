import { PropsWithChildren, createContext, useContext } from "react";

import { User, _getUser } from "./_api/user";
import { AccountType, accountTypes, useLoggedInAccounts } from "./auth";
import { getOrgAccountType, useSWROptional } from ".";

const UsersContext = createContext<Map<AccountType, User | null | undefined>>(
  null!
);

export function useUsers() {
  const users = useContext(UsersContext);
  if (!users) {
    throw new Error("Using `useUsers` hook outside of `UsersContext`");
  }
  return users;
}

export function useUsersOptional() {
  return useContext(UsersContext) as
    | Map<AccountType, User | null | undefined>
    | undefined;
}

export function UsersProvider({ children }: PropsWithChildren) {
  const accounts = useLoggedInAccounts();
  const users = new Map<AccountType, User | null | undefined>();
  for (const type of accountTypes) {
    try {
      const { data: user, error } = useSWROptional(
        _getUser,
        accounts.has(type) ? [type] : null
      );
      users.set(type, !accounts.has(type) || error ? null : user);
    } catch {
      // ignore error as auth token may be expired
    }
  }

  return (
    <UsersContext.Provider value={users}>{children}</UsersContext.Provider>
  );
}

const OrgUserContext = createContext<User | null | undefined>(undefined);

export function useOrgUser() {
  const user = useContext(OrgUserContext);
  if (!user) {
    throw new Error("Using `useOrgUser` hook while not logged in`");
  }
  return user;
}

export function OrgUserProvider({
  orgSlug,
  children,
}: PropsWithChildren<{ orgSlug: string }>) {
  const users = useUsers();

  return (
    <OrgUserContext.Provider value={users.get(getOrgAccountType(orgSlug))}>
      {children}
    </OrgUserContext.Provider>
  );
}
