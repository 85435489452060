import * as z from "zod";
import { preload } from "swr";
import Fraction from "fraction.js";

import * as nebulaClient from "../client";

export { nebulaClient, z };

export const zDate = z.preprocess((arg) => {
  if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const zLooseEnum = <U extends string, T extends Readonly<[U, ...U[]]>>(
  values: T
) =>
  z.union([
    z.enum(values),
    z.string().transform<`unknown_${string}`>((val) => `unknown_${val}`),
  ]);

export const zFraction = z.string().transform((val) => new Fraction(val));

export type APIGetter<Args extends unknown[] = unknown[], Res = unknown> = ((
  ...args: Args
) => Promise<Res>) & {
  _key: (...args: Args) => string;
  _fetcher: (...args: Args) => Promise<Res>;
};

export function createAPIGetter<Args extends unknown[], Res>(
  fetcher: (...args: Args) => Promise<Res>,
  key: (...args: Args) => string
): APIGetter<Args, Res> {
  const _fetcher = (...args: Args) =>
    fetcher(...args).catch((err) => {
      console.error(err);
      throw err;
    });
  const getter = ((...args: Args) =>
    preload(key(...args), () => _fetcher(...args))) as APIGetter<Args, Res>;
  getter._key = key;
  getter._fetcher = _fetcher;
  return getter;
}
