export const APIHostURL = import.meta.env.VITE_NEBULA_API_HOST;
export const APIBaseURL = `${APIHostURL}/v1`;

export const APITokenName = "NebulaAPIToken";
export const APITokenStoreName = "NebulaAPITokenStore";

const _uiPublicUrls = (
  import.meta.env.VITE_NEBULA_UI_PUBLIC_URLS || null
)?.split(",");
export const UIPublicHost = _uiPublicUrls
  ? (_uiPublicUrls.find((url) => url === window.location.host) ??
    _uiPublicUrls[0])
  : window.location.host;

export const UIPublicBaseURL = import.meta.env.PROD
  ? `https://${UIPublicHost}`
  : "http://localhost:5173";

export const VercelIntegrationInstallUrl = `https://vercel.com/integrations/${
  import.meta.env.VITE_VERCEL_INTEGRATION_NAME
}`;
export const GithubIntegrationBaseUrl = `https://github.com/apps/${
  import.meta.env.VITE_GITHUB_INTEGRATION_NAME
}/installations`;
