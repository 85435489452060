import { mutate } from "swr";
import { MutuallyRequired, StrictUnion } from "@/utils/types";

import { createAPIGetter, nebulaClient, z, zLooseEnum } from ".";
import { OperationType } from "./operations";
import { loggedInAccounts } from "../auth";
import { orgClient } from "../orgClient";

const InstanceStatusType = zLooseEnum([
  "creating",
  "available",
  "modifying",
  "deleting",
  "unavailable",
  "stopped",
  "stopping",
  "starting",
  "upgrading",
  "maintenance",
  "restoring",
  "suspending",
  "suspended",
  "resuming",
  "read_only_storage_exhausted",
  "read_only_storage_exausted",
]).transform((status) =>
  status === "read_only_storage_exausted"
    ? "read_only_storage_exhausted"
    : status
);

export type InstanceStatus = z.infer<typeof InstanceStatusType>;

const InstanceBillableType = z.object({
  name: zLooseEnum(["compute", "storage", "transfer"]),
  display_name: z.string(),
  display_unit: z.string(),
  display_quota: z.string(),
  internal_quota: z.number().optional(),
  resources: z.array(
    z.object({
      name: z.string(),
      display_divisor: z.number(),
      display_unit: z.string(),
      display_value: z.string(),
      internal_value: z.number().optional(),
    })
  ),
});

type InstanceBillable = z.infer<typeof InstanceBillableType>;

const InstanceType = z
  .object({
    id: z.string(),
    name: z.string(),
    status: InstanceStatusType,
    version: z.string(),
    org_slug: z.string(),
    dsn: z.string(),
    region: z.string(),
    tier: z.string(),
    egress_status: zLooseEnum(["GOODSTANDING", "WARNING", "BLOCKED"]),
    billables: z.array(InstanceBillableType).transform((billables) =>
      billables.reduce(
        (billables, billable) => {
          billables[billable.name] = billable;
          return billables;
        },
        {} as { [name in InstanceBillable["name"]]: InstanceBillable }
      )
    ),
  })
  .transform((instance) => ({
    ...instance,
    isAvailable:
      instance.status === "available" ||
      instance.status === "read_only_storage_exhausted",
  }));

const InstanceTypeArray = z.array(InstanceType);

export type Instance = z.infer<typeof InstanceType>;

export const getAllInstances = createAPIGetter(
  async () => {
    return (
      await Promise.all(
        [...loggedInAccounts()].map(async (accountType) =>
          InstanceTypeArray.parse(
            await nebulaClient.get(`instances`, undefined, accountType)
          )
        )
      )
    ).flat();
  },
  () => "instances"
);

export const getOrgInstances = createAPIGetter(
  async (orgSlug: string) => {
    const data = await orgClient(orgSlug).get(`instances`, true);
    return data ? InstanceTypeArray.parse(data) : null;
  },
  (orgSlug) => `orgs/${orgSlug}/instances`
);

export const getInstance = createAPIGetter(
  async (orgSlug: string, instanceName: string) => {
    const data = await orgClient(orgSlug).get(
      `instances/${instanceName}`,
      true
    );
    return data ? InstanceType.parse(data) : null;
  },
  (orgSlug, instanceName) => `orgs/${orgSlug}/instances/${instanceName}`
);

export type CreateInstanceParams = {
  orgSlug: string;
  name: string;
  version: string;
  region: string;
} & (
  | { tier: "Free" }
  | ({
      tier: "Pro";
      requested_resources: { name: string; value: number | string }[];
    } & MutuallyRequired<{
      source_instance_id: string;
      source_backup_id: string;
    }>)
);

export async function createInstance({
  orgSlug,
  ...args
}: CreateInstanceParams) {
  return OperationType.parse(
    await nebulaClient.post(`orgs/${orgSlug}/instances`, args)
  );
}

export type ModifyInstanceParams = {
  orgSlug: string;
  instanceName: string;
} & StrictUnion<
  | {
      version: string;
    }
  | {
      new_name: string;
    }
  | {
      compute: number | string;
    }
  | {
      storage: number;
    }
>;

export async function modifyInstance({
  orgSlug,
  instanceName,
  ...params
}: ModifyInstanceParams) {
  const op = OperationType.parse(
    await orgClient(orgSlug).put(
      `instances/${instanceName}`,
      "version" in params || "new_name" in params
        ? params
        : {
            tier: "Pro",
            requested_resources: [
              "compute" in params
                ? {
                    name: "compute",
                    value: params.compute,
                  }
                : {
                    name: "storage",
                    value: params.storage,
                  },
            ],
          }
    )
  );

  // mutate(getInstance._key(orgSlug, instanceName), (inst?: Instance | null) =>
  //   inst
  //     ? {
  //         ...inst,
  //         status:
  //           "version" in params
  //             ? ("upgrading" as const)
  //             : ("modifying" as const),
  //       }
  //     : null
  // );
  // mutate(getOrgInstances._key(orgSlug));

  return op;
}

export interface DeleteInstanceParams {
  orgSlug: string;
  name: string;
  version: string;
}

export async function deleteInstance({
  orgSlug,
  name,
  version,
}: DeleteInstanceParams) {
  const op = OperationType.parse(
    await orgClient(orgSlug).DELETE(`instances/${name}`, {
      version,
    })
  );
  mutate(getOrgInstances._key(orgSlug), async (instances?: Instance[]) =>
    instances?.map((inst) =>
      inst.name === name ? { ...inst, status: "deleting" as const } : inst
    )
  );
  return op;
}

export async function restartInstance({
  orgSlug,
  name,
}: {
  orgSlug: string;
  name: string;
}) {
  const op = OperationType.parse(
    await orgClient(orgSlug).post(`instances/${name}/restart`, null)
  );
  mutate(getOrgInstances._key(orgSlug), async (instances?: Instance[]) =>
    instances?.map((inst) =>
      inst.name === name ? { ...inst, status: "maintenance" as const } : inst
    )
  );
  return op;
}

export async function resumeInstance({
  orgSlug,
  name,
}: {
  orgSlug: string;
  name: string;
}) {
  const op = OperationType.parse(
    await orgClient(orgSlug).post(`instances/${name}/resume`, null)
  );
  return op;
}
