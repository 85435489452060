import { Outlet } from "react-router-dom";

import cn from "@edgedb/common/utils/classNames";
import { ThemeProvider } from "@edgedb/common/hooks/useTheme";
import { ModalProvider } from "@edgedb/common/hooks/useModal";
import { GlobalTooltipsProvider } from "@edgedb/common/hooks/useTooltips";
import { IncidentsBanner } from "@/components/incidentsBanner";

import styles from "./app.module.scss";
import themeStyles from "@edgedb/common/newui/theme.module.scss";

export default function RootLayout() {
  return (
    <>
      {import.meta.env["VITE_VERCEL_ENV"] === "production" ? null : (
        <div className="__testingBanner__">
          <span>{import.meta.env["VITE_NEBULA_API_HOST"]}</span>
        </div>
      )}
      <ThemeProvider>
        <div className={cn(styles.app, styles.theme, themeStyles.theme)}>
          <IncidentsBanner />
          <GlobalTooltipsProvider>
            <ModalProvider>
              <Outlet />
            </ModalProvider>
          </GlobalTooltipsProvider>
        </div>
      </ThemeProvider>
    </>
  );
}
