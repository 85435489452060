import { mutate } from "swr";
import { createAPIGetter, nebulaClient, z, zDate } from ".";
import { AccountType, loggedInAccounts } from "..";

export const UserType = z.object({
  id: z.string(),
  name: z.string(),
  full_name: z.string().optional(),
  github_handle: z.string().optional(),
  tos_agreement_date: zDate.nullable().optional(),
  avatar_url: z.string().nullable().optional(),
});

export type User = z.infer<typeof UserType>;

export const _getUser = createAPIGetter(
  async (accountType: AccountType) => {
    if (!loggedInAccounts().has(accountType)) return null;
    return UserType.parse(
      await nebulaClient.get("user", undefined, accountType)
    );
  },
  (tokenID) => `user@${tokenID}`
);

export interface UpdateUserParams {
  full_name: string;
  tos_agreement_date: string;
}

export async function updateUser(
  params: UpdateUserParams,
  account: AccountType
) {
  const user = UserType.parse(await nebulaClient.put(`user`, params, account));
  mutate(`user@${account}`);
  return user;
}

const DeleteAccountPrecheckType = z.object({
  has_vercel_integration: z.boolean(),
  has_github_installation: z.boolean(),
  has_instances: z.boolean(),
  is_sole_owner_of_orgs: z.boolean(),
});

export const deleteAccountPrecheck = createAPIGetter(
  async () =>
    DeleteAccountPrecheckType.parse(
      await nebulaClient.get("user/delete-precheck", undefined, "nebula")
    ),
  () => "user/delete-precheck"
);

export async function deleteUser() {
  await nebulaClient.DELETE("user", null, "nebula");
}

const GithubOrgType = z.object({
  name: z.string(),
  role: z.string(),
  exists: z.boolean(),
  org_slug: z.string().nullable().optional(),
  member: z.boolean(),
  requested: z.boolean(),
});

export type GithubOrg = z.infer<typeof GithubOrgType>;

const GithubOrgTypeArray = z.array(GithubOrgType);

export const getUserGithubOrgs = createAPIGetter(
  async () => {
    return GithubOrgTypeArray.parse(await nebulaClient.get(`user/githuborgs`));
  },
  () => `user/githuborgs`
);

export async function authCLISession(
  sessionId: string,
  accountType: AccountType
) {
  await nebulaClient.post(
    `auth/sessions/${sessionId}/authorize`,
    null,
    accountType
  );
}

const AuthSessionType = z.object({
  id: z.string(),
  auth_url: z.string(),
  token: z.string().nullable(),
  user_email: z.string().nullable(),
});

export async function getSessionFromId(sessionId: string) {
  return AuthSessionType.parse(
    await nebulaClient.get(`auth/sessions/${sessionId}`, false, false)
  );
}

export async function getUserFromSessionId(sessionId: string) {
  const session = await getSessionFromId(sessionId);

  if (session.token) {
    return getUserFromToken(session.token);
  }
  return null;
}

export async function getUserFromToken(token: string) {
  const res = await nebulaClient._fetchWithAuth(
    `user`,
    false,
    undefined,
    undefined,
    token
  );
  if (res.ok) {
    return UserType.parse(await res.json());
  }
  return null;
}
