import { mutate } from "swr";
import { createAPIGetter, z, zDate, zLooseEnum } from ".";
import { orgClient } from "../orgClient";
import { OperationType } from "./operations";

const BackupType = z.object({
  id: z.string(),
  created_on: zDate,
  type: z.enum(["automated", "on-demand"]),
  status: zLooseEnum(["complete", "running", "aborted", "pending", "failed"]),
  edgedb_version: z.string().optional(),
});

export type Backup = z.infer<typeof BackupType>;

const BackupArrayType = z.array(BackupType);

export const getInstanceBackups = createAPIGetter(
  async (orgSlug: string, instanceName: string) => {
    return BackupArrayType.parse(
      await orgClient(orgSlug).get(`instances/${instanceName}/backups`)
    );
  },
  (orgSlug: string, instanceName: string) =>
    `orgs/${orgSlug}/instances/${instanceName}/backups`
);

export async function getBackupById(
  orgSlug: string,
  instanceName: string,
  backupId: string
) {
  return BackupType.parse(
    await orgClient(orgSlug).get(
      `instances/${instanceName}/backups/${backupId}`
    )
  );
}

export async function createOnDemandBackup(
  orgSlug: string,
  instanceName: string
) {
  const op = OperationType.parse(
    await orgClient(orgSlug).post(`instances/${instanceName}/backups`, {})
  );
  mutate(getInstanceBackups._key(orgSlug, instanceName));
  return op;
}

export async function deleteBackup(
  orgSlug: string,
  instanceName: string,
  backupId: string
) {
  return OperationType.parse(
    await orgClient(orgSlug).DELETE(
      `instances/${instanceName}/backups/${backupId}`,
      null
    )
  );
}

export async function restoreBackup(
  orgSlug: string,
  instanceName: string,
  backupId: string
) {
  return OperationType.parse(
    await orgClient(orgSlug).post(`instances/${instanceName}/restore`, {
      backup_id: backupId,
    })
  );
}
