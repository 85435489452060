import { z } from ".";
import { createWebsocketConnection } from "../client";
import { APIBaseURL } from "../consts";
import { getOrgAccountType, orgClient } from "../orgClient";

const InstanceLogType = z.object({
  service: z.string(),
  severity: z.string(),
  time: z.string(),
  log: z.string(),
});

export type InstanceLog = z.infer<typeof InstanceLogType>;

const InstanceLogArrayType = z.object({ logs: z.array(InstanceLogType) });

export async function getInstanceLogs(
  orgSlug: string,
  instanceName: string,
  range: { start: string; to: string; limit?: number },
  backward = false
) {
  return InstanceLogArrayType.parse(
    await orgClient(orgSlug).get(
      `instances/${instanceName}/logs?${new URLSearchParams({
        start: range.start,
        to: range.to,
        direction: backward ? "backward" : "forward",
        ...(range.limit ? { limit: range.limit.toString() } : {}),
      }).toString()}`
    )
  ).logs;
}

export async function streamInstanceLogs({
  region,
  orgSlug,
  instanceName,
  onLog,
  onClose,
}: {
  region: string;
  orgSlug: string;
  instanceName: string;
  onLog: (log: InstanceLog) => void;
  onClose: () => void;
}) {
  const socket = await createWebsocketConnection(
    getOrgAccountType(orgSlug),
    `${APIBaseURL.replace(
      "https://api.g.",
      // It seems not all browsers follow redirects for websocket connection,
      // so connect directly to the api of the region hosting the instance
      `wss://api.${region.split("-").slice(1).join("-")}.r.`
    )}/orgs/${orgSlug}/instances/${instanceName}/logs/live`,
    "logs"
  );

  socket.addEventListener("message", (ev) => {
    const log = InstanceLogType.parse(JSON.parse(ev.data));
    onLog(log);
  });
  socket.addEventListener("error", (ev) => {
    console.error(ev);
    socket.close();
  });
  socket.addEventListener("close", () => onClose());

  // Explicitly send code 1000, so browser doesn't use 1005 which nebula
  // treats as an error
  return () => socket.close(1000);
}
