import { mutate } from "swr";
import { createAPIGetter, nebulaClient, z, zDate } from ".";
import { getOrg, getOrgs } from "./org";

const VercelInstallationDetailsType = z.object({
  configuration_id: z.string(),
  kind: z.enum(["team", "user"]),
  name: z.string(),
  slug: z.string(),
  avatar_id: z.string(),
  installed_on: zDate,
  installed_by: z.string(),
});

export const getVercelInstallationDetails = createAPIGetter(
  async (orgSlug: string) => {
    return VercelInstallationDetailsType.parse(
      await nebulaClient.get(`integrations/vercel/${orgSlug}`)
    );
  },
  (orgSlug) => `integrations/vercel/${orgSlug}`
);

export interface InstallVercelIntegrationToOrgParams {
  orgSlug: string;
  code: string;
}

export async function installVercelIntegrationToOrg({
  orgSlug,
  code,
}: InstallVercelIntegrationToOrgParams) {
  await nebulaClient.post(`integrations/vercel/${orgSlug}`, { code });
}

export async function uninstallVercelIntegrationFromOrg({
  orgSlug,
}: {
  orgSlug: string;
}) {
  await nebulaClient.DELETE(`integrations/vercel/${orgSlug}`, null);
  mutate(getOrg._key(orgSlug));
  mutate(getOrgs._key(null));
}

const VercelProjectType = z.object({
  id: z.string(),
  name: z.string(),
  instance_link: z
    .object({
      instance_id: z.string(),
      instance_name: z.string(),
      user_name: z.string(),
      created_on: zDate,
    })
    .nullable(),
});

export type VercelProject = z.infer<typeof VercelProjectType>;

const VercelProjectTypeArray = z.array(VercelProjectType);

export const getVercelProjects = createAPIGetter(
  async (orgSlug: string) => {
    return VercelProjectTypeArray.parse(
      await nebulaClient.get(`integrations/vercel/${orgSlug}/projects`)
    );
  },
  (orgSlug) => `integrations/vercel/${orgSlug}/projects`
);

export interface VercelProjectInstanceLinkParams {
  orgSlug: string;
  instanceName: string;
  projectId: string;
}

export async function linkVercelProjectToInstance({
  orgSlug,
  instanceName,
  projectId,
}: VercelProjectInstanceLinkParams) {
  await nebulaClient.post(
    `integrations/vercel/${orgSlug}/instances/${instanceName}/projects`,
    {
      project_id: projectId,
    }
  );
  mutate(getVercelProjects._key(orgSlug));
}

export async function refreshVercelLinkedProjectSecrets({
  orgSlug,
  instanceName,
  projectId,
}: VercelProjectInstanceLinkParams) {
  await nebulaClient.post(
    `integrations/vercel/${orgSlug}/instances/${instanceName}/projects/${projectId}/refresh-secrets`,
    null
  );
}

export async function unlinkVercelProjectFromInstance({
  orgSlug,
  instanceName,
  projectId,
}: VercelProjectInstanceLinkParams) {
  await nebulaClient.DELETE(
    `integrations/vercel/${orgSlug}/instances/${instanceName}/projects/${projectId}`,
    null
  );
  mutate(getVercelProjects._key(orgSlug));
}
