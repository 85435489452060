import { mutate } from "swr";
import Fraction from "fraction.js";
import {
  createAPIGetter,
  nebulaClient,
  z,
  zDate,
  zFraction,
  zLooseEnum,
} from ".";
import { GithubOrg, UserType, getUserGithubOrgs } from "./user";
import { getError } from "../client";
import { AccountType, loggedInAccounts } from "../auth";
import { getOrgAccountType, orgClient } from "../orgClient";

const OrgType = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  created_on: zDate,
  billing_email: z.string().optional(),
  preferred_payment_method: z.string().nullable(),
  vercel_integration_id: z.string().nullable(),
  github_integration_id: z.string().nullable().optional(),
});

export type Org = z.infer<typeof OrgType> & { accountType: AccountType };

const OrgTypeArray = z.array(OrgType);

export const getOrg = createAPIGetter(
  async (orgSlug: string) => {
    const data = await orgClient(orgSlug).get("", true);
    return data
      ? ({
          ...OrgType.parse(data),
          accountType: getOrgAccountType(orgSlug),
        } satisfies Org)
      : null;
  },
  (orgSlug) => `orgs/${orgSlug}`
);

export const getOrgs = createAPIGetter(
  async (accountType: AccountType | null) => {
    return (
      await Promise.all(
        [...loggedInAccounts()].map(async (type) =>
          accountType == null || type === accountType
            ? OrgTypeArray.parse(
                await nebulaClient.get(`orgs`, undefined, type)
              ).map((org) => ({ ...org, accountType: type }))
            : []
        )
      )
    ).flat() satisfies Org[];
  },
  (accountType) => (accountType ? `orgs@${accountType}` : `orgs`)
);

export interface UpdateOrgParams {
  orgSlug: string;
  name?: string;
  billing_email?: string;
  preferred_payment_method?: string;
}

export async function updateOrg({ orgSlug, ...params }: UpdateOrgParams) {
  const updatedOrg = {
    ...OrgType.parse(await orgClient(orgSlug).patch("", params)),
    accountType: getOrgAccountType(orgSlug),
  };
  mutate(getOrg._key(orgSlug), () => updatedOrg);
  mutate(getOrgs._key(null), (orgs?: Org[]) =>
    orgs?.map((org) => (org.id === updatedOrg.id ? updatedOrg : org))
  );
}

export interface RegisterOrgParams {
  name: string;
}

export async function registerOrg(params: RegisterOrgParams) {
  const newOrg = {
    ...OrgType.parse(await nebulaClient.post("orgs", params)),
    accountType: "nebula",
  } satisfies Org;
  mutate("orgs", (orgs?: Org[]) => (orgs ? [...orgs, newOrg] : undefined));
  return newOrg;
}

const MemberType = UserType.partial({
  full_name: true,
  github_handle: true,
  tos_agreement_date: true,
}).extend({
  level: z.preprocess(
    (val) => String(val).toLowerCase(),
    zLooseEnum(["owner", "member", "requester", "invitee"])
  ),
  org: z.string(),
  org_slug: z.string(),
  request_id: z.string().optional(),
});

export type Member = z.infer<typeof MemberType>;

const MemberTypeArray = z.array(MemberType);

export const getOrgMembers = createAPIGetter(
  async (orgSlug: string) => {
    const data = await nebulaClient.get(`orgs/${orgSlug}/members`, true);
    return data
      ? MemberTypeArray.parse(data).sort((a, b) =>
          a.github_handle === b.github_handle
            ? a.name.localeCompare(b.name)
            : (a.github_handle ?? "").localeCompare(b.github_handle ?? "")
        )
      : null;
  },
  (orgSlug) => `orgs/${orgSlug}/members`
);

export const getAllOrgMembers = createAPIGetter(
  async (accountType: AccountType) => {
    const orgs = await getOrgs(accountType);
    return await Promise.all(
      orgs.map(async (org) => ({
        ...org,
        members: await getOrgMembers(org.slug),
      }))
    );
  },
  (accountType) => `orgs/members@${accountType}`
);

export async function deleteOrg(orgSlug: string) {
  await nebulaClient.DELETE(`orgs/${orgSlug}`, null, "nebula");
}

export interface InviteOrgMemberParams {
  orgSlug: string;
  email: string;
  level: "owner" | "member";
}

export async function inviteOrgMember({
  orgSlug,
  ...params
}: InviteOrgMemberParams) {
  await nebulaClient.post(`orgs/${orgSlug}/members`, params);
  mutate(`orgs/${orgSlug}/members`);
}

export interface CancelOrgInvite {
  orgSlug: string;
  email: string;
}

export async function cancelOrgInvite({ orgSlug, ...params }: CancelOrgInvite) {
  await nebulaClient.DELETE(`orgs/${orgSlug}/members`, params);
  mutate(getOrgMembers._key(orgSlug), (members?: Member[]) =>
    members?.filter((member) => member.name !== params.email)
  );
}

export async function requestOrgMembership({ orgSlug }: { orgSlug: string }) {
  await nebulaClient.post(`orgs/${orgSlug}/membershiprequest`, null);
  mutate(getUserGithubOrgs._key(), (orgs?: GithubOrg[]) =>
    orgs?.map((org) =>
      org.name === orgSlug ? { ...org, requested: true } : org
    )
  );
}

export interface AcceptDenyMembershipRequest {
  orgSlug: string;
  request_id: string;
}

export async function denyOrgMembershipRequest({
  orgSlug,
  request_id,
}: AcceptDenyMembershipRequest) {
  await nebulaClient.DELETE(
    `orgs/${orgSlug}/membershiprequest/${request_id}`,
    null
  );
  await mutate(
    getOrgMembers._key(orgSlug),
    () => getOrgMembers._fetcher(orgSlug),
    { revalidate: false }
  );
}

export async function acceptOrgMembershipRequest({
  orgSlug,
  request_id,
}: AcceptDenyMembershipRequest) {
  await nebulaClient.put(
    `orgs/${orgSlug}/membershiprequest/${request_id}`,
    null
  );
  await mutate(
    getOrgMembers._key(orgSlug),
    () => getOrgMembers._fetcher(orgSlug),
    { revalidate: false }
  );
}

export interface UpdateOrgMemberParams {
  orgSlug: string;
  githubHandle: string;
  level: "owner" | "member";
}

export async function updateOrgMember({
  orgSlug,
  githubHandle,
  ...params
}: UpdateOrgMemberParams) {
  await nebulaClient.put(`orgs/${orgSlug}/members/${githubHandle}`, {
    ...params,
    level: params.level.toUpperCase(),
  });
  mutate(`orgs/${orgSlug}/members`, (members?: Member[]) =>
    (members ?? []).map((mem) =>
      mem.github_handle === githubHandle ? { ...mem, level: params.level } : mem
    )
  );
}

export interface RemoveOrgMemberParams {
  orgSlug: string;
  githubHandle: string;
}

export async function removeOrgMember({
  orgSlug,
  githubHandle,
}: RemoveOrgMemberParams) {
  await nebulaClient.DELETE(`orgs/${orgSlug}/members/${githubHandle}`, null);
  mutate(`orgs/${orgSlug}/members`, (members?: Member[]) =>
    (members ?? []).filter((mem) => mem.github_handle != githubHandle)
  );
}

export interface AcceptOrgInviteParams {
  loginToken?: string;
  inviteCode: string;
}

export async function acceptOrgInvite({
  loginToken,
  inviteCode,
}: AcceptOrgInviteParams) {
  const res = await nebulaClient._fetchWithAuth(
    `acceptorgmembership/${inviteCode}`,
    loginToken == null ? "nebula" : false,
    undefined,
    {
      method: "POST",
    },
    loginToken
  );
  if (!res.ok) {
    return await getError(res);
  }

  mutate(`orgs`);
  mutate("instances");
}

const PricingTierType = z.enum(["Free", "Pro"]);

type PricingTier = z.infer<typeof PricingTierType>;

const QuotaBillablesType = z
  .array(
    z.object({
      name: z.string(),
      display_name: z.string(),
      display_unit: z.string(),
      display_quota: zFraction,
    })
  )
  .transform((data) =>
    data.reduce(
      (map, { name, ...billableData }) => {
        map[name] = billableData;
        return map;
      },
      {} as {
        [name: string]: {
          display_name: string;
          display_unit: string;
          display_quota: Fraction;
        };
      }
    )
  );

const QuotasType = z.object({
  id: z.string(),
  limits: z.record(PricingTierType, QuotaBillablesType),
  usage: z.record(PricingTierType, QuotaBillablesType),
});

export const getQuotas = createAPIGetter(
  async (orgSlug: string) => {
    const data = QuotasType.parse(await orgClient(orgSlug).get("quotas"));
    const remaining = {} as {
      [tier in PricingTier]: {
        billable: { [name: string]: Fraction };
        anyRemainingQuota: boolean;
      };
    };
    for (const [tier, billables] of Object.entries(data.limits)) {
      let anyRemainingQuota = true;
      const remainingBillable = {} as { [name: string]: Fraction };
      for (const [name, { display_quota }] of Object.entries(billables)) {
        remainingBillable[name] = display_quota.sub(
          data.usage[tier as PricingTier]?.[name]?.display_quota ??
            new Fraction(0)
        );
        if (remainingBillable[name].compare(0) <= 0) {
          anyRemainingQuota = false;
        }
      }
      remaining[tier as PricingTier] = {
        billable: remainingBillable,
        anyRemainingQuota,
      };
    }
    return { ...data, remaining };
  },
  (orgSlug) => `orgs/${orgSlug}/quotas`
);

export type Quotas = Awaited<ReturnType<(typeof getQuotas)["_fetcher"]>>;
