import * as Sentry from "@sentry/react";

import { AccountType, getAPIToken, getAccountTypeWithToken, logout } from ".";
import { APIBaseURL } from "./consts";
import { backoffRetry } from "./utils";

export async function _fetchWithAuth(
  path: string,
  authID: AccountType | boolean,
  headers: Headers = new Headers(),
  opts: Omit<RequestInit, "headers"> = {},
  rawAuthToken?: string
) {
  let apiToken: string | null = null;
  if (authID === true) {
    authID = getAccountTypeWithToken() ?? false;
  }
  if (authID != false) {
    apiToken = getAPIToken(authID);
    if (!apiToken) {
      logout(authID);
    }
  } else if (rawAuthToken != null) {
    apiToken = rawAuthToken;
  }

  if (apiToken) {
    headers.append("Authorization", `Bearer ${apiToken}`);
    headers.append("X-Nebula-Authorization", `Bearer ${apiToken}`);
  }

  const res = await fetch(`${APIBaseURL}/${path}`, {
    headers,
    ...opts,
  });

  if ((res.status === 401 || res.status === 403) && authID != false) {
    // Auth token is invalid
    logout(authID);
  }

  return res;
}

export async function createWebsocketConnection(
  authID: AccountType,
  url: string,
  subproto: string
) {
  const apiToken = getAPIToken(authID);
  if (!apiToken) {
    logout(authID);
  }

  return new Promise<WebSocket>((resolve, reject) => {
    const socket = new WebSocket(url, [
      subproto,
      `X-Nebula-Authorization.${apiToken}`,
    ]);

    socket.addEventListener(
      "open",
      () => {
        resolve(socket);
      },
      { once: true }
    );

    socket.addEventListener(
      "error",
      (ev) => {
        if (socket.readyState !== WebSocket.OPEN) {
          reject(ev);
        }
      },
      { once: true }
    );
  });
}

export async function getError(res: Response) {
  try {
    const body = await res.json();
    if (body.error) {
      return new Error(body.error);
    }
  } catch {
    return new Error(
      `Request failed with code ${res.status}, and invalid JSON was returned in error body`
    );
  }
  return new Error(`Request failed with code ${res.status}: ${res.statusText}`);
}

function getJsonIfExists(res: Response) {
  return res.headers
    .get("content-type")
    ?.split(";")
    .includes("application/json")
    ? res.json()
    : null;
}

function wrapSentryContext<T>(
  ctx: { method: string; path: string; auth: unknown; body: unknown },
  call: () => T
) {
  return Sentry.withScope((scope) => {
    scope.setContext("nebulaAPI", { baseUrl: APIBaseURL, ...ctx });
    return call();
  });
}

export async function get(
  path: string,
  null404 = false,
  useAuthID: AccountType | boolean = true
) {
  return wrapSentryContext(
    { method: "get", path, body: null, auth: useAuthID },
    async () => {
      const res = await backoffRetry(() => _fetchWithAuth(path, useAuthID));

      if (!res.ok) {
        if (null404 && res.status === 404) {
          return null;
        }
        throw await getError(res);
      }
      return res.json();
    }
  );
}

export async function post(
  path: string,
  body: unknown,
  useAuthID: AccountType | boolean = true
) {
  return wrapSentryContext(
    { method: "post", path, body, auth: useAuthID },
    async () => {
      const res = await _fetchWithAuth(
        path,
        useAuthID,
        new Headers(
          body != null
            ? {
                "Content-Type": "application/json",
              }
            : undefined
        ),
        {
          method: "POST",
          body: body != null ? JSON.stringify(body) : undefined,
        }
      );
      if (!res.ok) {
        throw await getError(res);
      }
      return getJsonIfExists(res);
    }
  );
}

export async function DELETE(
  path: string,
  body: unknown,
  useAuthID: AccountType | boolean = true
) {
  return wrapSentryContext(
    { method: "delete", path, body, auth: useAuthID },
    async () => {
      const res = await _fetchWithAuth(
        path,
        useAuthID,
        body
          ? new Headers({
              "Content-Type": "application/json",
            })
          : undefined,
        {
          method: "DELETE",
          body: body ? JSON.stringify(body) : undefined,
        }
      );
      if (!res.ok) {
        throw await getError(res);
      }
      return getJsonIfExists(res);
    }
  );
}

export async function put(
  path: string,
  body: unknown,
  useAuthID: AccountType | boolean = true
) {
  return wrapSentryContext(
    { method: "put", path, body, auth: useAuthID },
    async () => {
      const res = await _fetchWithAuth(
        path,
        useAuthID,
        new Headers({
          "Content-Type": "application/json",
        }),
        {
          method: "PUT",
          body: body ? JSON.stringify(body) : undefined,
        }
      );
      if (!res.ok) {
        throw await getError(res);
      }
      return getJsonIfExists(res);
    }
  );
}

export async function patch(
  path: string,
  body: unknown,
  useAuthID: AccountType | boolean = true
) {
  return wrapSentryContext(
    { method: "patch", path, body, auth: useAuthID },
    async () => {
      const res = await _fetchWithAuth(
        path,
        useAuthID,
        new Headers({
          "Content-Type": "application/json",
        }),
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );
      if (!res.ok) {
        throw await getError(res);
      }
      return getJsonIfExists(res);
    }
  );
}
