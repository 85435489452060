import { mutate } from "swr";
import { createAPIGetter, nebulaClient, z, zDate, zLooseEnum } from ".";
import { AccountType, loggedInAccounts } from "../auth";

export type SecretKeyScope = {
  kind: "instance" | "database" | "role";
} & ({ all: true } | { all: false; value: string });

const scopeOrder = ["instance", "database", "role"] as const;

const _SecretKeyType = z.object({
  id: z.string(),
  name: z.string().nullable(),
  kind: zLooseEnum(["user-created", "web-login", "cli-login", "integration"]),
  created_on: zDate,
  expires_on: zDate.nullable(),
  description: z.string().nullable(),
  scopes: z.array(z.string()),
});

const secretKeyScopesTransform = <T extends z.infer<typeof _SecretKeyType>>(
  key: T
) =>
  ({
    ...key,
    raw_scopes: key.scopes,
    scopes: key.scopes
      .map((scope) => {
        const [kind, value] = scope.split(":");
        if (kind.endsWith(".all")) {
          return { kind: kind.slice(0, -5), all: true } as SecretKeyScope;
        }
        return { kind, all: false, value } as SecretKeyScope;
      })
      .sort((a, b) => scopeOrder.indexOf(a.kind) - scopeOrder.indexOf(b.kind)),
  }) as Omit<T, "scopes"> & { raw_scopes: string[]; scopes: SecretKeyScope[] };

const SecretKeyType = _SecretKeyType.transform(secretKeyScopesTransform);

const SecretKeyArrayType = z.array(SecretKeyType);

export type SecretKey = z.infer<typeof SecretKeyType>;

export type SecretKeyWithAccountType = SecretKey & { accountType: AccountType };

export const NewSecretKeyType = _SecretKeyType
  .extend({
    secret_key: z.string(),
  })
  .transform(secretKeyScopesTransform);

export type NewSecretKey = z.infer<typeof NewSecretKeyType>;

export const getSecretKeys = createAPIGetter(
  async () => {
    return (
      await Promise.all(
        [...loggedInAccounts()].map(async (accountType) =>
          SecretKeyArrayType.parse(
            await nebulaClient.get(`secretkeys`, undefined, accountType)
          ).map((key) => ({ ...key, accountType }))
        )
      )
    ).flat();
  },
  () => `secretkeys`
);

export async function createSecretKeyFromToken(
  token: string,
  params: CreateSecretKeyParams
) {
  const res = await nebulaClient._fetchWithAuth(
    "secretkeys",
    false,
    new Headers({
      "Content-Type": "application/json",
    }),
    {
      method: "POST",
      body: JSON.stringify({ ...params, kind: "web-login" }),
    },
    token
  );
  if (res.ok) {
    return NewSecretKeyType.parse(await res.json());
  }
  return null;
}

export interface CreateSecretKeyParams {
  name: string;
  description: string | null;
  ttl: string | null;
  scopes: string[] | null;
}

export async function createSecretKey(params: CreateSecretKeyParams) {
  const newKey = NewSecretKeyType.parse(
    await nebulaClient.post("secretkeys", {
      ...params,
      kind: "user-created",
    })
  );
  mutate("secretkeys", async (keys?: SecretKey[]) => [
    ...(keys ?? []),
    { ...newKey, secret_key: undefined },
  ]);
  return newKey;
}

export interface RevokeSecretKeysParams {
  ids: string[];
}

export async function revokeSecretKeys({ ids }: RevokeSecretKeysParams) {
  const res = await Promise.allSettled(
    ids.map(async (id) => {
      await nebulaClient.DELETE(`secretkeys/${id}`, null);
      return id;
    })
  );
  const revokedKeys = new Set(
    res
      .map((r) => (r.status === "fulfilled" ? r.value : null))
      .filter((id) => id != null) as string[]
  );
  await mutate("secretkeys", (keys?: SecretKey[]) =>
    keys?.filter((key) => !revokedKeys.has(key.id))
  );
  const errors = res
    .map((r) => (r.status === "rejected" ? r.reason : null))
    .filter((err) => err != null);
  if (errors.length) {
    throw new Error(`Failed to revoke some secret keys`, { cause: errors });
  }
  return null;
}
