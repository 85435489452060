import React from "react";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  createBrowserRouter as _createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import "@fontsource-variable/roboto-flex";
import "@fontsource-variable/roboto-mono";

import { routes } from "./routes";
import ErrorBoundary from "./errorBoundary";

import "./global.scss";

let createBrowserRouter = _createBrowserRouter;

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_VERCEL_ENV || "dev",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
  });

  createBrowserRouter = Sentry.wrapCreateBrowserRouterV6(_createBrowserRouter);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement, {
  onRecoverableError: Sentry.reactErrorHandler(),
}).render(
  <React.StrictMode>
    <RouterProvider
      router={createBrowserRouter([
        { ...routes[0], errorElement: <ErrorBoundary /> },
      ])}
    />
  </React.StrictMode>
);
