import { createAPIGetter, nebulaClient, z, zDate, zLooseEnum } from ".";
import { orgClient } from "../orgClient";

export const OperationType = z.object({
  id: z.string(),
  created_on: zDate,
  status: zLooseEnum(["in_progress", "failed", "completed"]),
  description: z.string(),
  message: z.string(),
});

export type Operation = z.infer<typeof OperationType>;

export type ResolvedOperation = {
  [k in keyof Operation]: k extends "status"
    ? Exclude<Operation[k], "in_progress">
    : Operation[k];
};

export async function getOp(opId: string, pollCount: number) {
  return OperationType.parse(
    await nebulaClient.get(`operations/${opId}?pollCount=${pollCount}`)
  );
}

export function sleep(milliseconds: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, milliseconds));
}

export async function pollOperation(
  op: Operation,
  waitSeconds = 1
): Promise<ResolvedOperation> {
  let pollCount = 1;
  while (op.status === "in_progress") {
    console.log(op.id);
    await sleep(waitSeconds * 1000);
    op = await getOp(op.id, pollCount++);
  }
  return op as ResolvedOperation;
}

const OperationArrayType = z.array(OperationType);

export interface GetInstanceOperationsProps {
  orgSlug: string;
  instanceName: string;
  from: Date | number;
  limit: number;
}

function _getInstanceOpsInstUrl({
  instanceName,
  from,
  limit,
}: GetInstanceOperationsProps) {
  return `instances/${instanceName}/operations?${new URLSearchParams({
    from: new Date(from).toISOString(),
    limit: limit.toString(),
  }).toString()}`;
}

export const getInstanceOperations = createAPIGetter(
  async (props: GetInstanceOperationsProps) => {
    return OperationArrayType.parse(
      await orgClient(props.orgSlug).get(_getInstanceOpsInstUrl(props))
    );
  },
  (props) => `orgs/${props.orgSlug}/${_getInstanceOpsInstUrl(props)}`
);
