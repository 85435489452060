import { mutate } from "swr";
import { createAPIGetter, nebulaClient, z, zDate, zLooseEnum } from ".";
import { Org, getOrg } from "./org";
import { getOrgAccountType, orgClient } from "../orgClient";

export const NewPaymentMethodType = z.object({
  id: z.string(),
  stripe_setup_intent_id: z.string(),
  stripe_setup_intent_client_secret: z.string(),
});

export type NewPaymentMethod = z.infer<typeof NewPaymentMethodType>;

export interface CreateOrgPaymentMethodParams {
  orgSlug: string;
}

export async function createOrgPaymentMethod({
  orgSlug,
}: CreateOrgPaymentMethodParams): Promise<NewPaymentMethod> {
  const data = await nebulaClient.post(`orgs/${orgSlug}/payment-methods`, {});
  const result = NewPaymentMethodType.parse(data);
  mutate(getOrgPaymentMethods._key(orgSlug));
  return result;
}

export interface DeleteOrgPaymentMethodParams {
  orgSlug: string;
  methodId: string;
}

export async function deleteOrgPaymentMethod({
  orgSlug,
  methodId,
}: DeleteOrgPaymentMethodParams) {
  const result = z
    .object({ new_preferred_payment_method: z.string().nullable() })
    .parse(
      await nebulaClient.DELETE(
        `orgs/${orgSlug}/payment-methods/${methodId}`,
        null
      )
    );
  mutate(getOrgPaymentMethods._key(orgSlug), (methods?: PaymentMethod[]) =>
    methods?.filter((method) => method.id !== methodId)
  );
  mutate(getOrg._key(orgSlug), (org?: Org) =>
    org
      ? {
          ...org,
          preferred_payment_method:
            result.new_preferred_payment_method ?? org.preferred_payment_method,
        }
      : undefined
  );
}

export interface UpdateOrgPaymentMethodParams {
  orgSlug: string;
  paymentMethodID: string;
  status: string;
}

export async function updateOrgPaymentMethod({
  orgSlug,
  paymentMethodID,
  status,
}: UpdateOrgPaymentMethodParams): Promise<void> {
  await nebulaClient.patch(
    `orgs/${orgSlug}/payment-methods/${paymentMethodID}`,
    { status: status }
  );
  mutate(getOrg._key(orgSlug));
  mutate(getOrgPaymentMethods._key(orgSlug));
}

const PaymentMethodType = z.object({
  id: z.string(),
  org: z.string(),
  created_on: zDate,
  card: z
    .object({
      brand: zLooseEnum([
        "amex",
        "diners",
        "discover",
        "eftpos_au",
        "jcb",
        "mastercard",
        "unionpay",
        "visa",
        "unknown",
      ]),
      exp_month: z.number(),
      exp_year: z.number(),
      last4: z.string(),
    })
    .nullable(),
  link: z
    .object({
      email_address: z.string(),
    })
    .nullable(),
});

const PaymentMethodArrayType = z.array(PaymentMethodType);

export type PaymentMethod = z.infer<typeof PaymentMethodType>;

export const getOrgPaymentMethods = createAPIGetter(
  async (orgSlug: string) => {
    return PaymentMethodArrayType.parse(
      await nebulaClient.get(`orgs/${orgSlug}/payment-methods`)
    );
  },
  (orgSlug) => `orgs/${orgSlug}/payment-methods`
);

export const getPaymentMethod = createAPIGetter(
  async (orgSlug: string, methodId: string) => {
    return PaymentMethodType.parse(
      await nebulaClient.get(`orgs/${orgSlug}/payment-methods/${methodId}`)
    );
  },
  (orgSlug, methodId) => `orgs/${orgSlug}/payment-methods/${methodId}`
);

const InvoiceType = z.object({
  number: z.string(),
  created_on: zDate,
  period_start: zDate,
  period_end: zDate,
  customer_name: z.string(),
  customer_email: z.string(),
  currency: z.string(),
  amount_due: z.number(),
  subtotal: z.number(),
  discount: z.number(),
  tax: z.number(),
  total: z.number(),
  stripe_invoice_url: z.string(),
});

export type Invoice = z.infer<typeof InvoiceType>;

const InvoiceArrayType = z.array(InvoiceType);

export const getOrgInvoices = createAPIGetter(
  async (orgSlug: string) => {
    return InvoiceArrayType.parse(await orgClient(orgSlug).get(`invoices`));
  },
  (orgSlug) => `orgs/${orgSlug}/invoices`
);

const InvoiceLine = z.object({
  description: z.string(),
  amount_excluding_tax: z.number(),
  quantity: z.number(),
  unit_amount_excluding_tax: z.string(),
});

const UpcomingInvoiceType = InvoiceType.extend({
  lines: z.array(InvoiceLine),
});

export const getOrgUpcomingInvoice = createAPIGetter(
  async (orgSlug: string) => {
    const data = UpcomingInvoiceType.parse(
      await nebulaClient.get(`orgs/${orgSlug}/invoices/upcoming`)
    );

    return {
      ...data,
      lines: data.lines
        .map((line) => {
          const [match, _desc, unitAmount] =
            line.description
              .trim()
              .match(/^.+\u00d7(.+)\(at (\$[0-9.]+).+\)$/) ?? [];
          if (!match) {
            return {
              desc: line.description,
              amount: line.amount_excluding_tax,
              unitsDesc: undefined,
            };
          }
          const desc = _desc.trim();
          if (desc.trim().startsWith("Pro Plan")) return null!;
          return {
            desc: desc.trim(),
            amount: line.amount_excluding_tax,
            unitsDesc: desc.startsWith("Compute")
              ? `${
                  line.quantity / 4
                } compute unit-hours @ ${unitAmount} / compute unit / hour`
              : desc.startsWith("Storage")
                ? `${line.quantity} GiB-hours @ ${unitAmount} / GiB / hour`
                : desc.startsWith("Data Transfer")
                  ? `${line.quantity} GiB @ ${unitAmount} / GiB`
                  : undefined,
          };
        })
        .filter((line) => line),
    };
  },
  (orgSlug) => `orgs/${orgSlug}/invoices/upcoming`
);

export type UpcomingInvoice = Awaited<
  ReturnType<(typeof getOrgUpcomingInvoice)["_fetcher"]>
>;

const BillableNamesType = z.enum(["compute", "storage", "transfer", "backup"]);

const _BillableUsageType = z.object({
  name: BillableNamesType,
  display_name: z.string(),
  unit: z.string().transform((unit) => (unit === "gigabyte" ? "GiB" : unit)),
  billing_increment_seconds: z.number().nullable(),
  billing_increment_display: z.string().nullable(),
  unit_price_cents: z.string(),
  bundled_amount: z.number().nullable(),
  usage: z
    .array(
      z.object({
        period_start: zDate,
        period_end: zDate,
        allocated_value: z.number().nullable(),
        metered_values: z
          .array(z.tuple([z.number(), z.number(), z.number()]))
          .nullable()
          .transform((values) => values?.sort((a, b) => a[0] - b[0]) ?? null),
        total_value: z.number(),
        total_billable_value: z.number(),
      })
    )
    .transform((usage) =>
      usage.sort((a, b) => a.period_end.getTime() - b.period_end.getTime())
    ),
});

function mapObject<O extends object, T>(
  obj: O,
  callback: (value: Exclude<O[keyof O], undefined>) => T
): { [key in keyof O]: T } {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, callback(v)])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
}

const meteringMultipliers: Record<string, number> = { GiB: 1024 ** 3 };

const InstanceBillableUsageType = z
  .object({
    id: z.string(),
    name: z.string(),
    region: z.string(),
    deleted: z.boolean(),
    billables: z.record(BillableNamesType, _BillableUsageType),
  })
  .transform((instanceUsage) => {
    const billables = mapObject(instanceUsage.billables, (billableUsage) => {
      const _usage = billableUsage.usage;
      const lastUsage = _usage[_usage.length - 1];
      let _estimatedUsage: (typeof _usage)[number] | undefined;
      if (lastUsage.allocated_value != null && !instanceUsage.deleted) {
        _estimatedUsage = {
          period_start: lastUsage.period_end,
          period_end: _currentParsePeriodEnd!,
          metered_values: null,
          allocated_value: lastUsage.allocated_value,
          total_value: 0,
          total_billable_value: 0,
        };
        _usage.push(_estimatedUsage);
      }

      const usage = _usage.map((u) => {
        const increment_period =
          (u.period_end.getTime() - u.period_start.getTime()) /
          (1000 * (billableUsage.billing_increment_seconds ?? 1));

        const estimated = u === _estimatedUsage;

        const total_billable_value = estimated
          ? increment_period *
            Math.max(
              0,
              u.allocated_value! - (billableUsage.bundled_amount ?? 0)
            )
          : u.total_billable_value;

        return {
          ...u,
          total_value: estimated
            ? increment_period * u.allocated_value!
            : u.total_value,
          total_billable_value,
          increment_period,
          cost_cents:
            parseFloat(billableUsage.unit_price_cents) * total_billable_value,
          estimated,
        };
      });

      const rawMeteredValueMultiplier =
        meteringMultipliers[billableUsage.unit] ?? 1;

      if (!_estimatedUsage && !instanceUsage.deleted) {
        const prevUsage = usage[usage.length - 1];

        const period_start = prevUsage.period_end;
        const period_end = _currentParsePeriodEnd!;
        const increment_period =
          (period_end.getTime() - period_start.getTime()) /
          (1000 * (billableUsage.billing_increment_seconds ?? 1));
        const bundled = billableUsage.bundled_amount ?? 0;

        let total_value: number;
        let total_billable_value: number;
        if (billableUsage.billing_increment_seconds != null) {
          const lastMeteredValue =
            prevUsage.metered_values![prevUsage.metered_values!.length - 1][2] /
            rawMeteredValueMultiplier;
          total_value = lastMeteredValue * increment_period;
          total_billable_value =
            Math.max(0, lastMeteredValue - bundled) * increment_period;
        } else {
          total_value =
            (prevUsage.total_value! / prevUsage.increment_period) *
            increment_period;
          total_billable_value = Math.max(
            0,
            prevUsage.total_value! +
              total_value -
              bundled -
              Math.max(0, prevUsage.total_value! - bundled)
          );
        }

        usage.push({
          period_start,
          period_end,
          allocated_value: null,
          metered_values: null,
          increment_period,
          total_value,
          total_billable_value,
          cost_cents:
            parseFloat(billableUsage.unit_price_cents) * total_billable_value,
          estimated: true,
        });
      }

      return {
        ...billableUsage,
        usage,
        rawMeteredValueMultiplier,
        cost_cents: usage.reduce((sum, u) => sum + u.cost_cents, 0),
        estimated_cost_cents: usage.reduce(
          (sum, u) => sum + (u.estimated ? u.cost_cents : 0),
          0
        ),
      };
    });
    return {
      ...instanceUsage,
      billables,
      cost_cents: Object.values(billables).reduce(
        (sum, b) => sum + b.cost_cents,
        0
      ),
      estimated_cost_cents: Object.values(billables).reduce(
        (sum, b) => sum + b.estimated_cost_cents,
        0
      ),
    };
  });
export type InstanceBillableUsage = z.infer<typeof InstanceBillableUsageType>;

export type BillableUsage = Exclude<
  InstanceBillableUsage["billables"][keyof InstanceBillableUsage["billables"]],
  undefined
>;

// yes, I know this is a little bit hacky
let _currentParsePeriodEnd: Date | null = null;
const CurrentBillingPeriodUsageType = z
  .object({
    period_start: zDate,
    period_end: zDate.refine((date) => {
      _currentParsePeriodEnd = date;
      return true;
    }),
    instances: z.array(InstanceBillableUsageType),
  })
  .transform((usage) => {
    _currentParsePeriodEnd = null;
    return usage;
  });

export type CurrentBillingPeriodUsage = z.infer<
  typeof CurrentBillingPeriodUsageType
> & {
  total_cost_cents: number;
  estimated_cost_cents: number;
};

export const getCurrentBillingPeriodUsage = createAPIGetter(
  async (orgSlug: string) => {
    const data = await orgClient(orgSlug).get(
      `invoices/current-period-usage`,
      true
    );
    if (!data) {
      return null;
    }
    const usage = CurrentBillingPeriodUsageType.parse(data);
    if (getOrgAccountType(orgSlug) === "vercel") {
      usage.instances = usage.instances.filter((inst) => !inst.deleted);
    }
    if (usage.instances.length === 0) {
      return null;
    }
    return {
      ...usage,
      total_cost_cents: usage.instances.reduce(
        (sum, i) => sum + i.cost_cents,
        0
      ),
      estimated_cost_cents: usage.instances.reduce(
        (sum, i) => sum + i.estimated_cost_cents,
        0
      ),
    };
  },
  (orgSlug) => `orgs/${orgSlug}/invoices/current-period-usage`
);
