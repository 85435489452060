import { AccountType } from "./auth";
import * as nebulaClient from "./client";

const vercelOrgRegexp = /^vercel-[A-Za-z0-9]{24}$/;

export function getOrgAccountType(orgSlug: string): AccountType {
  return orgSlug.startsWith("_v") ||
    orgSlug.startsWith("-v") ||
    vercelOrgRegexp.test(orgSlug)
    ? "vercel"
    : "nebula";
}

export function orgClient(orgSlug: string) {
  return {
    get(path: string, null404 = false) {
      return nebulaClient.get(
        `orgs/${orgSlug}/${path}`,
        null404,
        getOrgAccountType(orgSlug)
      );
    },
    post(path: string, body: unknown) {
      return nebulaClient.post(
        `orgs/${orgSlug}/${path}`,
        body,
        getOrgAccountType(orgSlug)
      );
    },
    DELETE(path: string, body: unknown) {
      return nebulaClient.DELETE(
        `orgs/${orgSlug}/${path}`,
        body,
        getOrgAccountType(orgSlug)
      );
    },
    put(path: string, body: unknown) {
      return nebulaClient.put(
        `orgs/${orgSlug}/${path}`,
        body,
        getOrgAccountType(orgSlug)
      );
    },
    patch(path: string, body: unknown) {
      return nebulaClient.patch(
        `orgs/${orgSlug}/${path}`,
        body,
        getOrgAccountType(orgSlug)
      );
    },
  };
}
