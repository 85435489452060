import { useSyncExternalStore } from "react";
import { mutate } from "swr";
import z from "zod";

import { APITokenName, APITokenStoreName } from "./consts";

import { createSecretKeyFromToken } from "./_api/secretkey";
import { createAPIGetter, nebulaClient } from "./_api";

export const accountTypes = ["nebula", "vercel"] as const;
export type AccountType = (typeof accountTypes)[number];

type TokenData = { id: string; token: string };
let tokenStore = new Map<AccountType, TokenData>();
let _loggedInAccounts: Set<AccountType> | null = null;

const _authListeners = new Set<() => void>();
function _updateListeners() {
  for (const listener of _authListeners) {
    listener();
  }
}
function _storeTokenStore() {
  localStorage.setItem(
    APITokenStoreName,
    JSON.stringify(
      [...tokenStore.entries()].reduce(
        (obj, [k, v]) => {
          obj[k] = v;
          return obj;
        },
        {} as Record<string, TokenData>
      )
    )
  );
}
function _refreshTokenStore(
  data: string | null = localStorage.getItem(APITokenStoreName)
) {
  if (data) {
    try {
      const parsed = JSON.parse(data);
      if (typeof parsed === "object") {
        tokenStore = new Map<AccountType, TokenData>(
          Object.entries(parsed)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map(([k, v]: [any, any]) =>
              !accountTypes.includes(k as AccountType) ||
              typeof v !== "object" ||
              typeof v?.id !== "string" ||
              typeof v?.token !== "string"
                ? null
                : [k, { id: v.id, token: v.token }]
            )
            .filter((entry) => entry != null) as [AccountType, TokenData][]
        );
      } else {
        tokenStore.clear();
      }
    } catch (e) {
      tokenStore.clear();
      console.error(`failed to refresh auth token store: ${e}`);
    }
  } else if (localStorage.getItem(APITokenName)) {
    tokenStore = new Map([
      ["nebula", { id: "", token: localStorage.getItem(APITokenName)! }],
    ]);
    localStorage.removeItem(APITokenName);
    _storeTokenStore();
  }
  _loggedInAccounts = null;
  _updateListeners();
}
addEventListener("storage", (event) => {
  if (event.storageArea === localStorage && event.key === APITokenStoreName) {
    _refreshTokenStore(event.newValue);
  }
});
_refreshTokenStore();

export function getAPIToken(tokenType: AccountType): string | null {
  return tokenStore.get(tokenType)?.token ?? null;
}

export function getAPITokenId(tokenType: AccountType): string | null {
  return tokenStore.get(tokenType)?.id ?? null;
}

export function getAccountTypeWithToken(): AccountType | null {
  return tokenStore.has("nebula")
    ? "nebula"
    : tokenStore.has("vercel")
      ? "vercel"
      : null;
}

export function setAPIToken(tokenID: AccountType, tokenData: TokenData | null) {
  if (tokenData == null) {
    if (!tokenStore.has(tokenID)) {
      return;
    }
    tokenStore.delete(tokenID);
  } else {
    if (tokenStore.get(tokenID)?.token === tokenData.token) {
      return;
    }
    tokenStore.set(tokenID, tokenData);
  }
  _loggedInAccounts = null;
  mutate(() => true);
  _storeTokenStore();
  _updateListeners();
}

function _getLoggedInAccountsSnapshot() {
  if (!_loggedInAccounts) {
    _loggedInAccounts = new Set(tokenStore.keys());
  }
  return _loggedInAccounts;
}
export function useLoggedInAccounts() {
  return useSyncExternalStore<Set<AccountType>>((callback) => {
    _authListeners.add(callback);
    return () => _authListeners.delete(callback);
  }, _getLoggedInAccountsSnapshot);
}

export function loggedInAccounts() {
  return _getLoggedInAccountsSnapshot();
}

export function logout(tokenID: AccountType) {
  setAPIToken(tokenID, null);
  if (tokenStore.size === 0) {
    window.location.pathname = "/";
  }
}

export async function setAPITokenFromLoginToken(loginToken: string) {
  const newToken = await createSecretKeyFromToken(loginToken!, {
    name: `Web-Login`,
    description: `Web login from user agent ${navigator.userAgent}`,
    scopes: null,
    ttl: null,
  });
  const token = newToken?.secret_key;
  if (token) {
    setAPIToken("nebula", { id: newToken.id, token });
  } else {
    logout("nebula");
  }
}

let authFailedState: {
  kind: "unknown" | "not_on_allowlist";
  status?: string;
  message: string;
} | null = null;
export function setAuthFailedState(err: string | null) {
  if (err === null) {
    authFailedState = null;
    return;
  }
  try {
    const parsedErr = JSON.parse(err);
    authFailedState = {
      kind:
        parsedErr?.error &&
        /Github user .+? is not yet allowed to sign up for (EdgeDB|Gel) Cloud/i.test(
          parsedErr.error
        )
          ? "not_on_allowlist"
          : "unknown",
      status: parsedErr?.status,
      message:
        parsedErr?.error ?? "An unexpected error occurred while logging in.",
    };
  } catch {
    authFailedState = {
      kind: "unknown",
      message: "An unexpected error occurred while logging in.",
    };
  }
}
export function getAuthFailedState() {
  return authFailedState;
}

// CLI login

export interface CLISessionLoginState {
  accountType: AccountType;
  sessionId: string;
  complete: boolean | null;
  error: string | null;
}

let _cliSessionLogin: CLISessionLoginState | null = null;

export function setCLISessionLogin(
  sessionId: string,
  accountType: AccountType
) {
  _cliSessionLogin = {
    accountType,
    sessionId,
    complete: null,
    error: null,
  };
  mutate("_cliSessionLogin");
}

export function updateCLISessionLogin(
  state: Pick<CLISessionLoginState, "complete" | "error"> | null
) {
  _cliSessionLogin = state ? { ..._cliSessionLogin!, ...state } : null;
  mutate("_cliSessionLogin");
}

export const getCLISessionLogin = createAPIGetter(
  async () => {
    return _cliSessionLogin;
  },
  () => "_cliSessionLogin"
);

// vercel sso

const VercelSSOResponseType = z.object({
  token: z.string(),
  org_slug: z.string(),
  instance_name: z.string().nullable(),
});

export async function vercelSSOSignin(params: {
  code: string;
  resource_id: string | null;
}) {
  const res = VercelSSOResponseType.parse(
    await nebulaClient.post("auth/vercel-sso", params, false)
  );
  setAPIToken("vercel", { id: "", token: res.token });

  return res;
}
