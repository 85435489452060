import _useSWR, { SWRResponse } from "swr";
import { createAPIGetter, z } from ".";
import { orgClient } from "../orgClient";

const MetricsValueType = z.object({
  time: z.number().transform((unixTimestamp) => unixTimestamp * 1000),
  value: z.number(),
});

export type MetricsValue = z.infer<typeof MetricsValueType>;

const RangeMetricsType = z.object({
  values: z
    .array(MetricsValueType)
    .transform((data) => data.sort((a, b) => a.time - b.time)),
});

const RangeMetricsResultType = z.object({
  metrics: z.array(
    z.object({
      instance_name: z.string(),
      metric: RangeMetricsType.nullable(),
      errors: z.array(z.string()).nullable(),
    })
  ),
});

type RangeMetricsResult = z.infer<typeof RangeMetricsResultType>;

const InstantMetricsResultType = z.object({
  metrics: z.array(
    z.object({
      instance_name: z.string(),
      metric: z.object({ value: MetricsValueType }).nullable(),
      errors: z.array(z.string()).nullable(),
    })
  ),
});

export interface MetricsQuery {
  start: number; // unix timestamp
  end: number; // unix timestamp
  step: `${number}${"h" | "m" | "s"}`;
}

export type MetricKind =
  | "queries_count"
  | "connections_count"
  | "data_transfer_usage"
  | "storage_available"
  | "cpu_usage"
  | "memory_usage"
  | "storage_size";

export interface GetRangeMetricsParams {
  orgSlug: string;
  kind: MetricKind;
  region: string;
  instances: string[];
  query: MetricsQuery;
}

export const getRangeMetrics = createAPIGetter(
  async ({
    orgSlug,
    kind,
    region,
    instances,
    query,
  }: GetRangeMetricsParams) => {
    return RangeMetricsResultType.parse(
      await orgClient(orgSlug).post(`instances/metrics/${kind}/range`, {
        region,
        instance_names: instances,
        ...query,
      })
    );
  },
  (params) => JSON.stringify(params)
);

const _getMultiRangeMetrics = createAPIGetter(
  async ({
    orgSlug,
    kinds,
    region,
    instances,
    query,
  }: Omit<GetRangeMetricsParams, "kind"> & { kinds: MetricKind[] }) => {
    return (
      await Promise.all(
        kinds.map((kind) =>
          orgClient(orgSlug).post(`instances/metrics/${kind}/range`, {
            region,
            instance_names: instances,
            ...query,
          })
        )
      )
    ).reduce<{ [key in MetricKind]?: RangeMetricsResult }>(
      (metrics, res, i) => {
        metrics[kinds[i]] = RangeMetricsResultType.parse(res);
        return metrics;
      },
      {} as { [key in MetricKind]?: RangeMetricsResult }
    );
  },
  (params) => JSON.stringify(params)
);

export function useMultiRangeMetrics<Kinds extends MetricKind[]>(
  kinds: Kinds,
  params: Omit<GetRangeMetricsParams, "kind">
) {
  const args = { ...params, kinds };
  return _useSWR(_getMultiRangeMetrics._key(args), () =>
    _getMultiRangeMetrics._fetcher(args)
  ) as SWRResponse<
    Pick<
      Required<Awaited<ReturnType<(typeof _getMultiRangeMetrics)["_fetcher"]>>>,
      Kinds[number]
    >
  >;
}

export interface GetInstantMetricsParams {
  orgSlug: string;
  kind: Exclude<MetricKind, "storage_available"> | "storage_size";
  region: string;
  instances: string[];
  time: number;
}

export const getInstantMetrics = createAPIGetter(
  async ({
    orgSlug,
    kind,
    region,
    instances,
    time,
  }: GetInstantMetricsParams) => {
    return InstantMetricsResultType.parse(
      await orgClient(orgSlug).post(`instances/metrics/${kind}/instant`, {
        region,
        instance_names: instances,
        time,
      })
    );
  },
  (params) => JSON.stringify(params)
);

export function convertToTimeSeries(data: MetricsValue[]) {
  const series: MetricsValue[] = [];
  for (let i = 0; i < data.length - 1; i++) {
    const item = data[i];
    const next = data[i + 1];
    const val = next.value - item.value;
    series.push({ time: item.time, value: val < 0 ? next.value : val });
  }
  return series;
}

export interface NormalizedMetricsValue {
  time: number;
  value: number | null;
}

export function normalizeMetricsData(
  data: MetricsValue[],
  query: MetricsQuery,
  stepSeconds: number,
  skipLast: boolean
) {
  let time = query.start * 1000;
  const newData: NormalizedMetricsValue[] = [];
  const nullRanges: [number, number][] = [];
  let cursor = 0;
  const end = (query.end + (skipLast ? -stepSeconds : 0)) * 1000;
  while (time <= end) {
    const item = data[cursor];
    let value: number | null = null;
    if (item?.time === time) {
      cursor++;
      value = item.value;
    }
    if (value === null) {
      if (
        nullRanges[nullRanges.length - 1]?.[1] ===
        time - stepSeconds * 1000
      ) {
        nullRanges[nullRanges.length - 1][1] = time;
      } else {
        nullRanges.push([time, time]);
      }
    }
    newData.push({
      time,
      value,
    });
    time += stepSeconds * 1000;
  }
  return { data: newData, nullRanges };
}
