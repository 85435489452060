import { useCallback, useEffect, useState } from "react";
import * as z from "zod";

const LocalStorageSpec = {
  PreferredOrg: z.string().nullable().default(null),
  DismissedOps: z
    .array(z.object({ id: z.string(), created: z.number() }))
    .default([]),
  ConnInfoOpened: z.array(z.string()).default([]),
  ChartOptions: z
    .object({
      useLocalTime: z.boolean().default(false),
    })
    .default({}),
  DismissedIncidents: z
    .array(z.object({ id: z.string(), expires: z.number() }))
    .default([]),
} satisfies Record<string, z.ZodDefault<z.ZodType>>;

export type LocalStorageTypes = {
  [Key in keyof typeof LocalStorageSpec]: ItemType<Key>;
};

type ItemType<Key extends keyof typeof LocalStorageSpec> = z.infer<
  (typeof LocalStorageSpec)[Key]
>;

export function getLocalStorageData<Key extends keyof typeof LocalStorageSpec>(
  key: Key
): ItemType<Key> {
  const spec = LocalStorageSpec[key];
  try {
    const data = localStorage.getItem(`NebulaUI_${key}`);
    return spec.parse(data ? JSON.parse(data) : undefined);
  } catch {
    // ignore
  }
  return spec.parse(undefined);
}

export function updateLocalStorageData<
  Key extends keyof typeof LocalStorageSpec,
>(
  key: Key,
  update: ItemType<Key> | ((data: ItemType<Key>) => ItemType<Key>)
): ItemType<Key> {
  const updated =
    typeof update === "function" ? update(getLocalStorageData(key)) : update;
  localStorage.setItem(`NebulaUI_${key}`, JSON.stringify(updated));
  return updated;
}

export function useLocalStorageData<Key extends keyof typeof LocalStorageSpec>(
  key: Key
): [ItemType<Key>, (update: (data: ItemType<Key>) => ItemType<Key>) => void] {
  const [data, setData] = useState(() => getLocalStorageData(key));

  const _update = useCallback(
    (update: (data: ItemType<Key>) => ItemType<Key>) => {
      setData(updateLocalStorageData(key, update));
    },
    [key, setData]
  );

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === `NebulaUI_${key}`) {
        setData(getLocalStorageData(key));
      }
    };

    window.addEventListener("storage", listener);

    return () => window.removeEventListener("storage", listener);
  }, [key, setData]);

  return [data, _update];
}
