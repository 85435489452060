import { mutate } from "swr";
import { createAPIGetter, nebulaClient, z, zDate } from ".";
import { getOrg, getOrgs } from "./org";
import { zLooseEnum } from ".";
import { getOrgAccountType } from "../orgClient";

export async function linkGithubInstallation({
  orgSlug,
  installationId,
}: {
  orgSlug: string;
  installationId: string;
}) {
  await nebulaClient.post(
    `integrations/github/${orgSlug}`,
    {
      installation_id: installationId,
    },
    getOrgAccountType(orgSlug)
  );
}

const GithubInstallationDetailsType = z.object({
  installation_id: z.string(),
  kind: zLooseEnum(["Organization", "User"]),
  github_id: z.string(),
  github_slug: z.string(),
  installed_on: zDate,
  installed_by: z.string(),
});

export type GithubInstallationDetails = z.infer<
  typeof GithubInstallationDetailsType
>;

export const getGithubInstallationDetails = createAPIGetter(
  async (orgSlug: string) => {
    return GithubInstallationDetailsType.parse(
      await nebulaClient.get(`integrations/github/${orgSlug}`)
    );
  },
  (orgSlug) => `integrations/github/${orgSlug}`
);

export async function uninstallGithubIntegrationFromOrg({
  orgSlug,
}: {
  orgSlug: string;
}) {
  await nebulaClient.DELETE(`integrations/github/${orgSlug}`, null);
  mutate(getOrg._key(orgSlug));
  mutate(getOrgs._key(null));
}

const GithubRepoType = z.object({
  id: z.string(),
  name: z.string(),
  default_branch: z.string(),
  instance_link: z
    .object({
      instance_id: z.string(),
      instance_name: z.string(),
      user_name: z.string(),
      created_on: zDate,
      root_dir: z.string(),
      protected_branches: z.array(z.string()),
      auto_cleanup_branches: z.boolean(),
    })
    .nullable(),
});

export type GithubRepo = z.infer<typeof GithubRepoType>;

const GithubRepoTypeArray = z.array(GithubRepoType);

export const getGithubRepos = createAPIGetter(
  async (orgSlug: string) => {
    return GithubRepoTypeArray.parse(
      await nebulaClient.get(`integrations/github/${orgSlug}/repos`)
    );
  },
  (orgSlug) => `integrations/github/${orgSlug}/repos`
);

export interface GithubRepoLinkParams {
  orgSlug: string;
  instanceName: string;
  repoId: string;
  rootDir: string;
  protectedBranches: string[];
  autoCleanupBranches: boolean;
}

export async function linkGithubRepoToInstance({
  orgSlug,
  instanceName,
  repoId,
  rootDir,
  protectedBranches,
  autoCleanupBranches,
}: GithubRepoLinkParams) {
  await nebulaClient.post(
    `integrations/github/${orgSlug}/instances/${instanceName}/repos`,
    {
      repo_id: repoId,
      root_dir: rootDir,
      protected_branches: protectedBranches,
      auto_cleanup_branches: autoCleanupBranches,
    }
  );
  mutate(getGithubRepos._key(orgSlug));
}

export interface GithubRepoUnlinkParams {
  orgSlug: string;
  instanceName: string;
  repoId: string;
}

export async function unlinkGithubRepoFromInstance({
  orgSlug,
  instanceName,
  repoId,
}: GithubRepoUnlinkParams) {
  await nebulaClient.DELETE(
    `integrations/github/${orgSlug}/instances/${instanceName}/repos/${repoId}`,
    null
  );
  mutate(getGithubRepos._key(orgSlug));
}

export interface UpdateLinkedGithubRepoParams {
  orgSlug: string;
  instanceName: string;
  repoId: string;
  rootDir: string;
  protectedBranches: string[];
  autoCleanupBranches: boolean;
}

export async function updateLinkedGithubRepo({
  orgSlug,
  instanceName,
  repoId,
  rootDir,
  protectedBranches,
  autoCleanupBranches,
}: UpdateLinkedGithubRepoParams) {
  await nebulaClient.post(
    `integrations/github/${orgSlug}/instances/${instanceName}/repos/${repoId}`,
    {
      root_dir: rootDir,
      protected_branches: protectedBranches,
      auto_cleanup_branches: autoCleanupBranches,
    }
  );
  mutate(getGithubRepos._key(orgSlug));
}

const GithubBranchType = z.object({
  branch_name: z.string(),
  deleted: z.boolean(),
  status: zLooseEnum(["updating", "error", "up-to-date"]),
  last_updated_at: zDate.nullable(),
  error_message: z.string().nullable(),
  latest_commit_sha: z.string(),
  db_branch_name: z.string().nullable(),
  pr_issue_number: z.number().nullable(),
  created_on: zDate,
});

export type GithubBranch = z.infer<typeof GithubBranchType>;

const GithubBranchTypeArray = z.array(GithubBranchType);

export const getLinkedGithubRepoBranches = createAPIGetter(
  async (orgSlug: string, instanceName: string, repoId: string) => {
    return GithubBranchTypeArray.parse(
      await nebulaClient.get(
        `integrations/github/${orgSlug}/instances/${instanceName}/repos/${repoId}/branches`
      )
    );
  },
  (orgSlug, instanceName, repoId) =>
    `integrations/github/${orgSlug}/instances/${instanceName}/repos/${repoId}/branches`
);
