import { assertNever } from "@edgedb/common/utils/assertNever";

const log1000 = Math.log(1000);
const log1024 = Math.log(1024);

const bytesUnits = ["B", "KiB", "MiB", "GiB", "TiB", "PiB"];
export function formatBytes(bytes: number, sep?: string) {
  return _formatNumber(bytes, bytesUnits, false, 1024, log1024, sep);
}

const prefixes = ["", "k", "M", "B", "T"];
export function formatNumber(num: number, fractions = false) {
  return _formatNumber(num, prefixes, fractions);
}

function _formatNumber(
  num: number,
  units: string[],
  fractions: boolean,
  base = 1000,
  logBase = log1000,
  sep = ""
) {
  if (num === 0) {
    return `0${sep}${units[0]}`;
  }
  if (!fractions && num != Math.round(num)) {
    return "";
  }
  const unitIndex = Math.max(0, Math.floor(Math.log(num) / logBase));
  const scaled = num / Math.pow(base, unitIndex);
  // eslint-disable-next-line prefer-const
  let [int, dec] = scaled.toPrecision(scaled >= 1000 ? 4 : 3).split(".");
  dec = dec?.replace(/0+$/, "");
  return int + (dec ? "." + dec : "") + sep + units[unitIndex];
}

export function compareVersions(a: string, b: string) {
  return a.localeCompare(b, undefined, { numeric: true });
}

export function formatTime(
  date: Date,
  format: "hour" | "day" | "month",
  local: boolean,
  prevDate: Date | null
): string {
  const timeZone = local ? undefined : "UTC";
  const dateStr = date.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    timeZone,
  });
  switch (format) {
    case "hour":
    case "day": {
      const timeStr = date.toLocaleString(undefined, {
        hour: "numeric",
        minute: "numeric",
        timeZone,
      });
      return (format === "day" && !prevDate) ||
        (prevDate &&
          prevDate.toLocaleString(undefined, {
            month: "short",
            day: "numeric",
            timeZone,
          }) !== dateStr)
        ? `${timeStr}\n${dateStr}`
        : timeStr;
    }
    case "month": {
      const yearStr = date.toLocaleString(undefined, {
        year: "numeric",
        timeZone,
      });
      return prevDate &&
        prevDate.toLocaleString(undefined, { year: "numeric", timeZone }) !==
          yearStr
        ? `${dateStr}\n${yearStr}`
        : dateStr;
    }
    default:
      assertNever(format);
  }
}
