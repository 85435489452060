import _useSWR, { SWRConfiguration } from "swr";
import { APIGetter } from "./_api";

export function useSWR<Args extends unknown[], Res>(
  getter: APIGetter<Args, Res>,
  ...args: Args
) {
  return _useSWR(getter._key(...args), () => getter._fetcher(...args));
}

export function useSWRWithOpts<Args extends unknown[], Res>(
  opts: SWRConfiguration<Res>,
  getter: APIGetter<Args, Res>,
  ...args: Args
) {
  return _useSWR(getter._key(...args), () => getter._fetcher(...args), opts);
}

export function useSWROptional<Args extends unknown[], Res>(
  getter: APIGetter<Args, Res>,
  args: Args | null
) {
  return _useSWR(args ? getter._key(...(args as Args)) : null, () =>
    getter._fetcher(...(args as Args))
  );
}

export function useSWRImmutable<Args extends unknown[], Res>(
  getter: APIGetter<Args, Res>,
  ...args: Args
) {
  return _useSWR(getter._key(...args), () => getter._fetcher(...args), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
}

export * from "./auth";
export { getOrgAccountType } from "./orgClient";

export * from "./_api/instance";
export * from "./_api/org";
export * from "./_api/operations";
export * from "./_api/secretkey";
export * from "./_api/user";
export * from "./_api/versions";
export * from "./_api/metrics";
export * from "./_api/pricing";
export * from "./_api/billing";
export * from "./_api/logs";
export * from "./_api/backups";
export * from "./_api/vercel";
export * from "./_api/github";

export * from "./userContext";
