import { useEffect } from "react";

import { useSWRWithOpts } from "@/nebulaAPI";
import { getIncidents } from "@/nebulaAPI/_api/external";
import { useLocalStorageData } from "@/utils/localStorage";

import cn from "@edgedb/common/utils/classNames";
import {
  CrossIcon,
  ExternalLinkIcon,
  InfoIcon,
  WarningIcon,
} from "@edgedb/common/newui";

import styles from "./incidentsBanner.module.scss";

const incidentTypes: Record<string, { title: string; style: string }> = {
  degraded_performance: {
    title: "degraded performance",
    style: styles.degradedPerf,
  },
  partial_outage: {
    title: "partial outage",
    style: styles.partialOutage,
  },
  full_outage: {
    title: "full outage",
    style: styles.fullOutage,
  },
};

export function IncidentsBanner() {
  const { data: incidents } = useSWRWithOpts(
    { refreshInterval: 300_000 },
    getIncidents
  );
  const [dismissed, updateDismissed] =
    useLocalStorageData("DismissedIncidents");

  useEffect(() => {
    const now = Date.now() - 60_000;
    updateDismissed((dismissed) => dismissed.filter((d) => d.expires > now));
  }, []);

  if (incidents?.ongoing_incidents.length) {
    const first = incidents.ongoing_incidents[0];

    let title =
      incidentTypes[first.current_worst_impact]?.title ?? "ongoing incident";
    if (!first.status.startsWith("unknown_")) {
      title =
        first.status === "identified"
          ? `${title} identified`
          : `${first.status} ${title}`;
    }
    title = title[0].toUpperCase() + title.slice(1);

    return (
      <div
        className={cn(
          styles.banner,
          incidentTypes[first.current_worst_impact]?.style
        )}
      >
        <div className={styles.incidentType}>
          <WarningIcon />
          {title}
          <a className={styles.moreInfo} href={first.url} target="_blank">
            More info
            <ExternalLinkIcon />
          </a>
        </div>
        <span>|</span>
        <div className={styles.message}>{first.name}</div>
        <div className={styles.lastUpdateTime}>
          Last update at{" "}
          {first.last_update_at.toLocaleString(undefined, {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
          })}{" "}
          UTC
        </div>
        <span>|</span>
        <a className={styles.moreInfo} href={first.url} target="_blank">
          More info
          <ExternalLinkIcon />
        </a>
      </div>
    );
  }

  if (
    incidents?.in_progress_maintenances.length ||
    incidents?.scheduled_maintenances.length
  ) {
    const first =
      incidents.in_progress_maintenances[0] ??
      incidents.scheduled_maintenances[0];
    if (dismissed.some(({ id }) => first.id === id)) {
      return null;
    }
    const inProgress = !!incidents.in_progress_maintenances.length;

    return (
      <div className={styles.banner}>
        <div className={styles.incidentType}>
          <InfoIcon />
          {inProgress
            ? "Maintenance in progress"
            : "Upcoming scheduled maintenance"}
          <a className={styles.moreInfo} href={first.url} target="_blank">
            More info
            <ExternalLinkIcon />
          </a>
        </div>
        <span>|</span>
        <div className={styles.content}>
          <div className={styles.message}>{first.name}</div>
          <div className={styles.maintenanceTime}>
            {first.starts_at.toLocaleString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "UTC",
            })}{" "}
            &mdash;{" "}
            {first.ends_at.toLocaleString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "UTC",
            })}{" "}
            UTC
          </div>
        </div>
        <span>|</span>
        <a className={styles.moreInfo} href={first.url} target="_blank">
          More info
          <ExternalLinkIcon />
        </a>
        <div
          className={styles.dismissButton}
          onClick={() =>
            updateDismissed((dismissed) => [
              ...dismissed,
              {
                id: first.id,
                expires: (inProgress
                  ? first.ends_at
                  : first.starts_at
                ).getTime(),
              },
            ])
          }
        >
          <CrossIcon />
        </div>
      </div>
    );
  }

  return null;
}
